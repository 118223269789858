<template>
  <Defaultlayout :userprofile="userprofile">
    <PageSlider></PageSlider>
    <section class="about-us">
      <div class="container">
        <h1 class="page-heading text-center">Terms of Use</h1>
        <div class="description terms-of-use">
          <ol type="I" class="roman-listing">
            <!-- point number 1 -->
            <li>
              <h2 class="section-heading">Terms Applicable To All Website Visitors:</h2>
              <ol class="number-listing">
                <li>
                  <h3>Terms might change without prior notice:</h3>
                  <p>We reserve the right to modify these Terms at any time without any prior notice. Any modification will be effective upon posting of the updated Terms, and your access to and/or use of any of the Sites thereafter will constitute your acceptance of such modified Terms. Please review these Terms on a regular basis.</p>
                </li>
                <li>
                  <h3>Our offers might change without notice:</h3>
                  <p>We reserve the right to change or terminate any services, programs, promotions, offers, deals, coupons, specials described or offered through our site at any time without giving any prior notice to you. Not all products are available at all of our facilities or at all times. Some or all of the Offerings or products may not be available in your preferred location. References to any Offerings or products on the Sites that may be available in one location do not warrant or imply that Company offers or intends to offer them in any other location. Our company controls and operates the Sites from the United States and makes no representation that the Sites are appropriate or available for access and/or use in any other country.</p>
                </li>
                <li>
                  <h3>We grant you a limited license to use our content for personal use and non-commercial use only.</h3>
                  <p>All information, data, materials, text, graphics, images, and other content available on the Sites, including Third Party Content, Medical Content and Nutritional Content are provided for informational purposes and personal uses only. We grant you a personal, non-exclusive, non-transferable, non-assignable, non-sublicensable, limited license to access and/or use those portions of the Content solely for your own personal, non-commercial use (unless we provide prior written authorization). We do not allow you to copy or post the Content on any network computer, or transmit, distribute, publish, display, make available or broadcast the Content in any media or platform. We do not allow you the right to modify or alter the Content in any way or delete or change any copyright or trademark notice. No right, title or interest in and to any Content is transferred to you as a result of this limited license.</p>
                </li>
                <li>
                  <h3>Links to third-party sites</h3>
                  <p>For your convenience other third-party links might be provided on this website. We are not responsible for the content or the third-party policies of those third-party sites and you are solely responsible for using the third part link at your own discretion and/or risk. You are solely responsible to review each of the third-party site’s privacy policy and terms of use. We do not endorse the third-party links provided on the website. </p>
                </li>
                <li>
                  <h3>Please check with us before using our hyperlinks.</h3>
                  <p>If you post content online by maintaining a website, by blogging, or through any other means, you may include within that content a hyperlink to the home page or sign-in page of any of our site. All other linking to any of our site is strictly prohibited without prior written authorization from us. We accept no responsibility or liability for the quality, content, timeliness, accuracy, nature, or reliability of any content that you or others post online, even if it contains a hyperlink to one of our Sites.</p>
                </li>
                <li>
                  <h3>We Can Use or Disclose Your Feedback. </h3>
                  <p>Any comments or materials that you submit to Company, including, without limitation, any questions, comments, suggestions, or other feedback regarding Company, its products and services, or any of the information on the Sites, ("Feedback") will be considered to be non-confidential and non-proprietary. Company shall be free to use and disclose, with no compensation to you, any ideas, concepts, know-how, techniques, methods or methodologies contained in any such Feedback for any purpose whatsoever, including but not limited to developing, manufacturing, and marketing products and/or services incorporating such Feedback.<a href="https://shop.gharbazaar.com/tou">Table of Contents</a> </p>
                </li>
                <li>
                  <h3>You Must Be of 18 Years of age or older to order. </h3>
                  <p>You must be 18 years of age or older and a resident of the United States in order to use either the Online Grocery Ordering Service. </p>
                </li>
                <li>
                  <h3>You Must Create an Account for the Online Grocery Ordering Service.</h3>
                  <p>To use the Online Grocery Ordering Service, you must set up an Account. You agree to use and access your Account solely for lawful purposes consistent with the service(s) offered through the Account. When you create an Account, you must provide us with certain information about you and create a password. You agree to provide us with accurate and complete information requested in the registration process, and to inform us of any updates to such information on an ongoing basis to keep it accurate and complete. You agree that you will not impersonate any other person, set up multiple Accounts, or use your Account(s) in any way that circumvents limits on offers, discounts, promotions or coupons, and that we may terminate any Account we believe has engaged in such activities. You agree to keep your password confidential and not share it with anybody else.</p>
                </li>
                <li>
                  <h3>Additional Disclaimer</h3>
                  <p>This site, including all materials, services, software, information and other content available on and/or through this site, is provided by Ghar Bazaar “as is”, without warranty of any kind, expressed or implied, including but not limited to implied warranties of merchandise, fitness for a particular purpose, non-infringement, continued availability, quality, accuracy and/or system compatibility. In addition, no warranties shall arise from any course of performance, course of dealing or usage of trade.<br>
                  By using this site, you assume all of the risks associated with such use, and you expressly release Ghar bazaar from any and all liability, claims and/or losses arising from or connected with such risks.<br>
                  You acknowledge that: the site could include technical or other mistakes, inaccuracies or typographical errors; the site may become inoperable or otherwise unavailable for periods of time; materials and/or services at the site may be or become out of date and Ghar Bazaar makes no commitment to update such materials or services. Ghar Bazaar assumes no responsibility for errors or omissions in the information, documents, software, materials and/or services which are referenced by or linked to this site. References to third parties, their services and products, are provided “as is” without warranty of any kind, either express or implied.<br>
                  The information provided on this site is provided for general consumer understanding and educational purposes only and should not be interpreted as a recommendation for a specific treatment plan, product or course of action. The information may contain inaccuracies and typographical errors. Although Ghar Bazaar may update this site, some of the information may be out of date or contain other errors. Ghar Bazaar does not warrant the accuracy or completeness of any information posted or any opinion, advice or statement displayed on this site. You agree that your use of this site is at your sole risk.<br>
                  10. Placing an Online Grocery Order. </p>
                  <ol class="pl-4 abcd-listing aditional-disclaimer">
                    <!-- <li>
                    <span class=" font-bold">You Must Make a Minimum Purchase. </span>
                    <p>The minimum purchase amount of $35 must be met to place an order. The minimum purchase amount is the sum total of the order calculated from the quantities and online prices for the Products selected, but excluding all other charges and discounts, such as any applicable taxes, service or delivery fees, bag fees, promotional discounts etc.</p>
                    </li> -->
                    <li>
                      <span class="font-bold"> We May Limit Quantities. </span>
                      <p>We reserve the right to limit the quantity of any particular product ordered in our discretion, including without limitation, due to inventory control or other reasons.</p>
                    </li>
                    <li>
                      <span class="font-bold"> You Agree to Pay for Your Order.  </span>
                      <p>By submitting an order via the Online Grocery Ordering Service, you offer to purchase the Product(s) specified in your order. We will send you an electronic confirmation that your order has been received and the offer accepted, subject to our final confirmation of Product availability and price; however, we also reserve the right to cancel your order, in whole or in part, at any time in our sole discretion, and to refuse to provide delivery.</p>
                    </li>
                    <li>
                      <span class=" font-bold">  We Accept Major Credit, Debit Cards and Certain Other Payment Methods.   </span>
                      <p>You must select one of the payment methods given to you during the checkout. No exceptions can be made. By providing payment card information, you consent to and authorize storage of your card data (Name, Credit Card number, Expiration Date) for purposes of future online transactions, all transactions made via the Sites and the Company charging your selected payment method in the amount of each such transaction. Order processing will not occur until authorized. You represent and warrant to Company that your use of your selected payment method is authorized and legal. It is your responsibility to promptly notify your bank, financial institution or other responsible party of any changes to your billing information, or the loss, theft, or unauthorized use of your payment method. You are responsible for updating your payment information with Company whenever it is no longer current/accurate. </p>
                    </li>
                    <li>
                      <span class=" font-bold"> Changing or Canceling Your Order. </span>
                      <p>Since all orders are delivered within 24 hours the orders cannot be canceled or changed once placed.</p>
                    </li> 
                  </ol>
                </li>
                <li>
                  <h3>Availability Of Service In Countries </h3>
                  <p>Ghar Bazaar operates this Site from its office within the United States. Gharbazaar serves areas in Allen & McKinney Cities of Texas. Please keep in mind that this Site may not conform with laws of your country. If you access this Site from outside the United States, you do so at your own risk. You may not use the Site in violation of United States export laws and regulations. </p>
                </li>
                <li>
                  <h3>Delivery Options: </h3>
                  <ol type="A" class="pl-4 abcd-listing">
                    <li>
                      <span class="font-bold">Limitations: </span>
                      <p>Every Customer will be provided 4 FREE DELIVERIES per Month & Every Order should be of at least $35.</p>
                    </li>
                     <li>
                      <span class=" font-bold">Delivery Charges: </span>
                      <p>After 4 free deliveries each order will have $5 delivery charges.</p>
                    </li>
                    <li>
                      <span class=" font-bold">Delays if any: </span>
                      <p>Our team will notify the shopper about the delays associated with his order if any.</p>
                    </li>
                    <li>
                      <span class=" font-bold">Delivery Address </span>
                      <p>The shopper is responsible to provide us the correct mailing address for the delivery, and any failure to do so may result in order cancelation and penalty charges to cover our delivery and packaging cost.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Non-Acceptance </h3>
                  <p>If these terms and conditions are not acceptable in full, you must immediately terminate your use of this site. </p>
                </li>

              </ol>
            </li>
            <!-- point number 2 -->
            <li>
              <h2 class="section-heading">Terms Applicable To All App Users:</h2>
              <ol class="number-listing">
                <li>
                  <h3>Terms might change without prior notice:</h3>
                  <p>We reserve the right to modify these Terms at any time without any prior notice. Any modification will be effective upon posting of the updated Terms, and your access to and/or use of any of the App thereafter will constitute your acceptance of such modified Terms. Please review these Terms on a regular basis.</p>
                </li>
                <li>
                  <h3>Our offers might change without notice:</h3>
                  <p>We reserve the right to change or terminate any services, programs, promotions, offers, deals, coupons, specials described or offered through our app at any time without giving any prior notice to you. Not all products are available at all of our facilities or at all times. Some or all of the Offerings or products may not be available in your preferred location. References to any Offerings or products on the app that may be available in one location do not warrant or imply that Company offers or intends to offer them in any other location. Our company controls and operates the app from the United States and makes no representation that the app is appropriate or available for access and/or use in any other country.</p>
                </li>
                <li>
                  <h3>We grant you a limited license to use our content for personal use and non-commercial use only.</h3>
                  <p>All information, data, materials, text, graphics, images, and other content available on the app, including Third Party Content, Medical Content and Nutritional Content are provided for informational purposes and personal uses only. We grant you a personal, non-exclusive, non-transferable, non-assignable, non-sublicensable, limited license to access and/or use those portions of the Content solely for your own personal, non-commercial use (unless we provide prior written authorization). We do not allow you to copy or post the Content on any network computer, or transmit, distribute, publish, display, make available or broadcast the Content in any media or platform. We do not allow you the right to modify or alter the Content in any way or delete or change any copyright or trademark notice. No right, title or interest in and to any Content is transferred to you as a result of this limited license.</p>
                </li>
                <li>
                  <h3>Links to third-party sites</h3>
                  <p>For your convenience other third-party links might be provided on this app. We are not responsible for the content or the third-party policies of those third-party sites and you are solely responsible for using the third part link at your own discretion and/or risk. You are solely responsible to review each of the third-party site’s privacy policy and terms of use. We do not endorse the third-party links provided on the website.</p>
                </li>
                <li>
                  <h3>Please check with us before using our hyperlinks.</h3>
                  <p>If you post content online by maintaining a website, by blogging, or through any other means, you may include within that content a hyperlink of our app. We accept no responsibility or liability for the quality, content, timeliness, accuracy, nature, or reliability of any content that you or others post online, even if it contains a hyperlink to our app.</p>
                </li>
                <li>
                  <h3>We Can Use or Disclose Your Feedback.</h3>
                  <p>Any comments or materials that you submit to Company, including, without limitation, any questions, comments, suggestions, or other feedback regarding Company, its products and services, or any of the information on the app, ("Feedback") will be considered to be non-confidential and non-proprietary. Company shall be free to use and disclose, with no compensation to you, any ideas, concepts, know-how, techniques, methods or methodologies contained in any such Feedback for any purpose whatsoever, including but not limited to developing, manufacturing, and marketing products and/or services incorporating such Feedback.<a href="https://shop.gharbazaar.com/tou">Table of Contents</a></p>
                </li>
                <li>
                  <h3>You Must Be of 18 Years of age or older to order.</h3>
                  <p>You must be 18 years of age or older and a resident of the United States in order to use either the Online Grocery Ordering Service. </p>
                </li>
                <li>
                  <h3>You Must Create an Account for the Online Grocery Ordering Service.</h3>
                  <p>To use our app, you must set up an Account. You agree to use and access your Account solely for lawful purposes consistent with the service(s) offered through the Account. When you create an Account, you must provide us with certain information about you and create a password. You agree to provide us with accurate and complete information requested in the registration process, and to inform us of any updates to such information on an ongoing basis to keep it accurate and complete. You agree that you will not impersonate any other person, set up multiple Accounts, or use your Account(s) in any way that circumvents limits on offers, discounts, promotions or coupons, and that we may terminate any Account we believe has engaged in such activities. You agree to keep your password confidential and not share it with anybody else.</p>
                </li>
                <li>
                  <h3>Additional Disclaimer</h3>
                  <p>This app, including all materials, services, software, information and other content available on and/or through this app, is provided by Ghar Bazaar “as is”, without warranty of any kind, expressed or implied, including but not limited to implied warranties of merchandise, fitness for a particular purpose, non-infringement, continued availability, quality, accuracy and/or system compatibility. In addition, no warranties shall arise from any course of performance, course of dealing or usage of trade.<br>
                  By using this app, you assume all of the risks associated with such use, and you expressly release Ghar bazaar from any and all liability, claims and/or losses arising from or connected with such risks.<br>
                  You acknowledge that: the app could include technical or other mistakes, inaccuracies or typographical errors; the app may become inoperable or otherwise unavailable for periods of time; materials and/or services at the app may be or become out of date and Ghar Bazaar makes no commitment to update such materials or services. Ghar Bazaar assumes no responsibility for errors or omissions in the information, documents, software, materials and/or services which are referenced by or linked to this app. References to third parties, their services and products, are provided “as is” without warranty of any kind, either express or implied.<br>
                  The information provided on this app is provided for general consumer understanding and educational purposes only and should not be interpreted as a recommendation for a specific treatment plan, product or course of action. The information may contain inaccuracies and typographical errors. Although Ghar Bazaar may update this app, some of the information may be out of date or contain other errors. Ghar Bazaar does not warrant the accuracy or completeness of any information posted or any opinion, advice or statement displayed on this app. You agree that your use of this app is at your sole risk.<br>
                  10. Placing an Online Grocery Order.</p>
                  <ol type="A" class="pl-4 abcd-listing aditional-disclaimer">
                    <!-- <li>
                      <span class="font-bold">You Must Make a Minimum Purchase.  </span>
                      <p>The minimum purchase amount of $35 must be met to place an order. The minimum purchase amount is the sum total of the order calculated from the quantities and online prices for the Products selected, but excluding all other charges and discounts, such as any applicable taxes, service or delivery fees, bag fees, promotional discounts etc. </p>
                    </li> -->
                    <li>
                      <span class="font-bold"> We May Limit Quantities.  </span>
                      <p>We reserve the right to limit the quantity of any particular product ordered in our discretion, including without limitation, due to inventory control or other reasons.  </p>
                    </li>
                    <li>
                      <span class="font-bold">You Agree to Pay for Your Order. </span>
                      <p>By submitting an order via the Online Grocery Ordering Service, you offer to purchase the Product(s) specified in your order. We will send you an electronic confirmation that your order has been received and the offer accepted, subject to our final confirmation of Product availability and price; however, we also reserve the right to cancel your order, in whole or in part, at any time in our sole discretion, and to refuse to provide delivery.</p>
                    </li>
                    <li>
                      <span class="font-bold"> We Accept Major Credit, Debit Cards and Certain Other Payment Methods. </span>
                      <p>You must select one of the payment methods given to you during the checkout. No exceptions can be made. By providing payment card information, you consent to and authorize storage of your card data (Name, Credit Card number, Expiration Date) for purposes of future online transactions, all transactions made via the app and the Company charging your selected payment method in the amount of each such transaction. Order processing will not occur until authorized. You represent and warrant to Company that your use of your selected payment method is authorized and legal. It is your responsibility to promptly notify your bank, financial institution or other responsible party of any changes to your billing information, or the loss, theft, or unauthorized use of your payment method. You are responsible for updating your payment information with Company whenever it is no longer current/accurate.  </p>
                    </li>
                    <li>
                      <span class="font-bold"> Changing or Canceling Your Order.  </span>
                      <p>All orders can be canceled until they are in pending state. Orders once accepted & confirmed cannot be canceled. </p>
                    </li>
                  </ol>
                </li> 
                <!-- <li>
                  <h3>Availability Of Service In Countries</h3>
                  <p>Ghar Bazaar operates this app from its office within the United States. Ghar Bazaar serves areas in Allen & McKinney Cities of Texas. Please keep in mind that this app may not conform with laws of your country. If you access this app from outside the United States, you do so at your own risk. You may not use the app in violation of United States export laws and regulations.</p>
                </li>
                <li>
                  <h3>Delivery Options:</h3>
                  <p>Ghar Bazaar operates this app from its office within the United States. Ghar Bazaar serves areas in Allen & McKinney Cities of Texas. Please keep in mind that this app may not conform with laws of your country. If you access this app from outside the United States, you do so at your own risk. You may not use the app in violation of United States export laws and regulations.</p>
                  <ol type="A" class="pl-4 abcd-listing">
                    <li>
                      <span class="font-bold">Limitations </span>
                      <p>Every Customer will be provided 4 FREE DELIVERIES per Month & Every Order should be of at least $35.</p>
                    </li>
                    <li>
                        <span class="font-bold">Delivery Charges:</span>
                        <p>Free Delivery up to 25 miles: all order worth $35 or more and under 25 miles from 1640 FM 423, Little Elm, TX-75036, Will get FREE home delivery.Orders less than $35 and under 25 miles will be charged a delivery fee for $4.</p>
                        <p>Delivery fee for order 25 miles to 50 miles: all orders worth $55 or more within the distance of 25 miles to 50 miles from 1640 FM 423, Little Elm, TX-75036, will get FREE delivery. Orders less than $55 will be charged a delivery fee of $7.</p>
                    </li>
                    <li>
                      <span class="font-bold">Delays if any:</span>
                      <p>Our team will notify the shopper about the delays associated with his order if any.</p>
                    </li>
                    <li>
                      <span class="font-bold">Delivery Address</span>
                      <p>The shopper is responsible to provide us the correct mailing address for the delivery, and any failure to do so may result in order cancelation and penalty charges to cover our delivery and packaging cost.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Non-Acceptance</h3>
                  <p>If these terms and conditions are not acceptable in full, you must immediately terminate your use of this app.</p>
                </li> -->
                <li>
                  <h3>Placing an Online Grocery Order: We May Limit Quantities.</h3>
                  <p>We reserve the right to limit the quantity of any particular product ordered in our discretion, including without limitation, due to inventory control or other reasons.</p>
                </li>
                <li>
                  <h3>You Agree to Pay for Your Order:</h3>
                  <p>By submitting an order via the Online Grocery Ordering Service, you offer to purchase the Product(s) specified in your order. We will send you an electronic confirmation that your order has been received and the offer accepted, subject to our final confirmation of Product availability and price; however, we also reserve the right to cancel your order, in whole or in part, at any time in our sole discretion, and to refuse to provide delivery.</p>
                  <ol type="A" class="pl-4 abcd-listing">
                    <li>
                      <span class="font-bold">We Accept Major Credit, Debit Cards and Certain Other Payment Methods. </span>
                      <p>You must select one of the payment methods given to you during the checkout. No exceptions can be made. By providing payment card information, you consent to and authorize storage of your card data (Name, Credit Card number, Expiration Date) for purposes of future online transactions, all transactions made via the app and the Company charging your selected payment method in the amount of each such transaction. Order processing will not occur until authorized. You represent and warrant to Company that your use of your selected payment method is authorized and legal. It is your responsibility to promptly notify your bank, financial institution or other responsible party of any changes to your billing information, or the loss, theft, or unauthorized use of your payment method. You are responsible for updating your payment information with Company whenever it is no longer current/accurate.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Changing or Canceling Your Order:</h3>
                  <p>All orders can be canceled until they are in pending state. Orders once accepted & confirmed cannot be canceled.</p>
                  <ol type="A" class="pl-4 abcd-listing">
                    <li>
                      <span class="font-bold">Availability Of Service In Countries:</span>
                      <p>Ghar Bazaar operates this Site from its office within the United States. Ghar Bazaar serves Dallas, Denton, Keller, Fort Worth, Grand Prairie, Grapevine, Euless, Bedford, Arlington, Flower Mound, Coppell, Farmers Branch, Irving, Little Elm, Lewisville, Prosper, Aubrey, Corinth, Krogerville, Crossroads, Oak Point, The Colony, Frisco, Allen, Plano, Carrollton, Mckinney, Richardson, Rockwall, Wylie, Murphy, Garland, Rowlett, Parker, Sachse, Highland Park, Anna, Celina, Roanoke, University Park, & Addison and many other areas. Check the app to see if your address is covered. Cities of Texas. Please keep in mind that this Site may not conform with laws of your country. If you access this Site from outside the United States, you do so at your own risk. You may not use the Site in violation of United States export laws and regulations.</p>
                    </li>
                    <li>
                        <span class="font-bold">Delivery Charges:</span>
                        <p><span class="font-bold">Free Delivery up to 25 miles:</span> all orders worth $39 or more and under 25 miles from 1640 FM 423, Little Elm, Tx-75036, will get FREE home delivery. Orders less than $39 and those under 25 miles will be charged a delivery fee of $4.</p>
                        <p><span class="font-bold">Delivery fee for orders 25 miles to 50 miles:</span> all orders worth $39 or more within the distance of 25 miles to 50 miles from 1640 FM 423, Little Elm, Tx-75036, will get FREE delivery. Orders less than $39 will be charged a delivery fee of $7.</p>
                    </li>
                    <li>
                        <span class="font-bold">Delays if any:</span>
                        <p>Our team will notify the shopper about the delays associated with his order if any.</p>
                    </li>
                    <li>
                        <span class="font-bold">Delivery Address:</span>
                        <p>The shopper is responsible to provide us the correct mailing address for the delivery, and any failure to do so may result in order cancelation and penalty charges to cover our delivery and packaging cost.</p>
                    </li>
                    <li>
                        <span class="font-bold">Non-Acceptance:</span>
                        <p>If these terms and conditions are not acceptable in full, you must immediately terminate your use of this app.</p>
                    </li>

                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <p style="margin-top: -10px;">To see our Privacy & Policy, Please <a href=" https://www.gharbazaar.com/privacy-policy" target="_blank">Click Here</a></p>

        </div>
      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
  name: "TermsOfUse",
  components: {
    Defaultlayout,
    PageSlider,
  },
  data() {
    return {
      loading: true,
      userprofile: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
};
</script>